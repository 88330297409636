import React from 'react';
import { FaTimes } from "react-icons/fa";
import '../styles/modal.scss'

function Modal({ heading, isOpen, onClose, children, classes }) {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={`modal fixed inset-0 flex items-center justify-center z-40`} onClick={handleOverlayClick}>
      <div className="modal-overlay absolute inset-0 bg-gray-700 opacity-50"></div>
      <div className={`modal-content bg-main-alt w-[30rem] max-w-full rounded-lg shadow-lg z-50 ${classes}`} onClick={handleContentClick}>
        <div className="modal-header p-6 px-10 rounded-t-lg text-black flex justify-between items-center border-b border-b-50">
          <h2 className='text-2xl font-semibold'>{heading}</h2>
          <button className="modal-close text-main hover:text-main-dark" onClick={onClose}><FaTimes size={20}/></button>
        </div>
        <div className="modal-body p-10">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;

import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LiaTimesSolid } from "react-icons/lia";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchloading, setSearchloading] = useState(false);
  const [userToken, setuserToken] = useState("");
  const [autherror, setAutherror] = useState("");
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userToken"));
    setuserToken(token);
  }, []);
  
  const ToastCloseButton = ({ closeToast }) => (
    <LiaTimesSolid size={20} className="text-main mt-3" onClick={closeToast}/>
  );

  const login = async (email, password) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/user/login`, {
        email,
        password,
      });

      if (response.data.message === "success") {
        localStorage.clear();
        localStorage.setItem("userToken", JSON.stringify(response.data.userToken[0]));
        setIsLoggedIn(true);
        setuserToken(response.data.userToken[0]);
        toast("Login successful");
        return response.data;
      } else {
        toast.error(response.data.message);
        return response.data;
      }
    } catch (error) {
      toast.error("Login failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const signup = async (company, insta, name, lastname, email, password, phone, address, type) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/user/register`, {
        company,
        insta,
        name,
        lastname,
        email,
        password,
        phone,
        address,
        type,
      });
      if (response.data.message === "success") {
        // perform additional actions if needed
        setRegistered(true);
        return "success";
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Signup failed: " + error);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    // perform logout logic and set isLoggedIn to false
    setIsLoggedIn(false);
    localStorage.clear();
    return "success";
  };

  const searchproducts = async (txt) => {
    try {
      const userToken = JSON.parse(localStorage.getItem("userToken"));
      setSearchloading(true);
      const response = await axios.post(`${BASE_URL}/products/search`, {
        txt,
        place: "home",
      });

      if (response.data.message === "success") {
        setSearchloading(false);
        return response.data.products;
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed: " + error);
    }
    setSearchloading(false);
  };

  const forgotpassword = async (email) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/forgotpassword`, {
        email,
      });
      if (response.data.message === "success") {
        // perform additional actions if needed
        setIsLoading(false);
        return "success";
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
    }
  };

  const validatetoken = async (token) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}/user/validatetoken/${token}`);
      if (response.data.message === "success" || response.data.message === "invalid") {
        // perform additional actions if needed
        setIsLoading(false);
        return response.data.message;
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to validate reset token: " + error);
    }
  };

  const resetpassword = async (token, newpassword, confirmpassword) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/user/resetpassword/${token}`, {
        newpassword,
        confirmpassword,
      });
      if (response.data.message === "success") {
        // perform additional actions if needed
        setIsLoading(false);
        return "success";
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Request failed: " + error);
      setIsLoading(false);
    }
  };

  const verify = async (token) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}/user/verify/${token}`);
      if (response.data.message === "success") {
        // perform additional actions if needed
        setIsLoading(false);
        return response.data.message;
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
        return response.data.message;
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Request failed: " + error);
    }
  };

  return (
    <AuthContext.Provider value={{ userToken, setuserToken, setIsLoggedIn, isLoggedIn, autherror, registered, isLoading, login, signup, verify, logout, searchproducts, forgotpassword, validatetoken, resetpassword, searchloading }}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" closeButton={ToastCloseButton}/>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
